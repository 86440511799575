import React, { useState, useEffect } from 'react';
import { useMsal } from "@azure/msal-react";
import { loginRequest } from "./authConfig";
import { jwtDecode } from 'jwt-decode';
import { fetchProviderIdWithEntra, fetchGroupId, fetchGroupPlans, fetchProviderOrganization } from './helpers/apiHelpers';
import { faker } from '@faker-js/faker';
import JsonViewer from './JsonViewer';
import { Tabs, Tab, Box, Typography } from '@mui/material';

const UserForm = () => {
  const { instance, accounts } = useMsal();
  const [ctypes] = useState(['Minimal - Select fields', 'Quick - Phone# only']);
  const [atypes, setAtypes] = useState(['Create a test case', 'Get case debug info']);
  
  const [formData, setFormData] = useState({
    apienv: '',
    plan: '',
    ctype: ctypes.length > 0 ? ctypes[1] : '',
    firstName: '',
    lastName: '',
    dob: '',
    gender: '',
    phoneNumber: '',
    postalCode: '',
    atype: '',
    caId: '',
  });

  const [selectedTab, setSelectedTab] = useState(0);
  const [caseJson, setCaseJson] = useState({});
  const [patientJson, setPatientJson] = useState({});
  const [providerJson, setProviderJson] = useState({});
  const [organizationJson, setOrganizationJson] = useState({});
  const [organizationGroupJson, setOrganizationGroupJson] = useState({});
  const [outcomePlanJson, setOutcomePlanJson] = useState({});
  const [outcomePlanTaskGraphTemplatesJson, setOutcomePlanTaskGraphTemplatesJson] = useState({});
  const [outcomePlanInstanceJson, setOutcomePlanInstanceJson] = useState({});
  const [outcomePlanInstanceTaskGraphInstancesJson, setOutcomePlanInstanceTaskGraphInstancesJson] = useState({});
  const [outcomePlanInstanceNextExecutionTimeJson, setOutcomePlanInstanceNextExecutionTimeJson] = useState({});
  const [outcomePlanInstanceExecutionsJson, setOutcomePlanInstanceExecutionsJson] = useState({});
  const [outcomePlanInstanceSchedulerStatusJson, setOutcomePlanInstanceSchedulerStatusJson] = useState({});
  const [outcomePlanInstanceInteractionRequestsJson, setOutcomePlanInstanceInteractionRequestsJson] = useState({});
  const [outcomePlanInstanceInteractionResponsesJson, setOutcomePlanInstanceInteractionResponsesJson] = useState({}); 

  const TabPanel = ({ children, value, index }) => {
    return (
      <div role="tabpanel" hidden={value !== index}>
        {value === index && (
          <Box sx={{ p: 3 }}>
            <Typography component={'div'}>{children}</Typography>
          </Box>
        )}
      </div>
    );
  };

  const [plans, setPlans] = useState([]);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [groupIdMap, setGroupIdMap] = useState('');
  const [planIdMap, setPlanIdMap] = useState([]);
  const [providerId, setProviderId] = useState('');
  const [caseId, setCaseId] = useState('');
  const [caId, setCaId] = useState('');

  useEffect(() => {
    const fetchPlans = async () => {
      try {
        const tokenRequest = {
          scopes: [
            process.env.REACT_APP_AUTH_SCOPE,
            process.env.REACT_APP_AUTH_SCOPE_PROVIDER,
            process.env.REACT_APP_AUTH_SCOPE_GROUP
          ],  
          account: accounts[0],
        };
        const response = await instance.acquireTokenSilent(tokenRequest);
        const accessToken = response.accessToken;

        const decoded = jwtDecode(accessToken);
        const sub = decoded.sub;
        const providerId = await fetchProviderIdWithEntra(sub, accessToken);
        setProviderId(providerId);
        const groupIdMap = await fetchGroupId(providerId, accessToken);
        setGroupIdMap(groupIdMap);
        const orgMap = await fetchProviderOrganization(providerId, accessToken);
        console.log('Organization ID:', orgMap.id);
        let planIdMap = await fetchGroupPlans(groupIdMap.id, orgMap.id, providerId, accessToken);
        setPlanIdMap(planIdMap);

        let newAvailablePlans = [];        
        planIdMap.forEach(plan => {
          newAvailablePlans.push(plan.name);
        });
        setPlans(newAvailablePlans);

        console.log('Allowed Groups:', process.env.REACT_APP_ALLOWED_GROUPS_TO_CREATE_CASES);
        if (process.env.REACT_APP_ALLOWED_GROUPS_TO_CREATE_CASES === '*' || groupIdMap.id === process.env.REACT_APP_ALLOWED_GROUPS_TO_CREATE_CASES) {
          setAtypes(['Create a test case', 'Get case debug info']);
          setFormData({ ctype: ctypes.length > 0 ? ctypes[1] : '', atype: 'Create a test case' });      
        } else {
          setAtypes(['Get case debug info']);
          setFormData({ ctype: ctypes.length > 0 ? ctypes[1] : '', atype: 'Get case debug info' });
        }
  
      } catch (error) {
        console.error('Error fetching plans:', error);
      }
    };

    fetchPlans();
  }, [instance, accounts, groupIdMap.id, ctypes]);

  // Handle form input changes
  const handleChange = (e, newValue) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
    if (newValue === undefined)
      newValue = 0;
    setSelectedTab(newValue);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);

    // Submit form data to API
    try {

      // Request object to get access token
      const tokenRequest = {
        ...loginRequest,
        account: accounts[0],
        scopes: [
          process.env.REACT_APP_AUTH_SCOPE,
          process.env.REACT_APP_AUTH_SCOPE_PROVIDER,
          process.env.REACT_APP_AUTH_SCOPE_GROUP
        ],
      };

      // Get the access token
      const { accessToken } = await instance.acquireTokenSilent(tokenRequest);

      switch (formData.atype) {
        case 'Create a test case':
          // get fake data
          const firstName = faker.person.firstName();
          const lastName = faker.person.lastName();
          const dob = faker.date.birthdate({ mode: 'age', min: 18, max: 65 }).toISOString().split('T')[0];
          const randomGender = faker.helpers.arrayElement(['Male', 'Female', 'Other']);
          const randomzip = faker.location.zipCode('#####');
          const randommrn = faker.string.uuid();
          const randomumrn = faker.string.uuid();
          const randompronoun = faker.helpers.arrayElement(['he/him', 'she/her', 'they/them']);
          const randomconversationStyle = faker.helpers.arrayElement(['formal', 'casual', 'informal']);

          // Define the API endpoint
          let apiEndpoint = ''; // API endpoint to submit form data
          formData.apienv = process.env.REACT_APP_ENV;
          let adate = new Date();

          // Get the plan ID
          let newFoundPlanId = '';
          let aptType = '';
          let planType = '';
          planIdMap.forEach(plan => {
            if (plan.name === formData.plan) {
              newFoundPlanId = plan.id;
              planType = plan.type;
              aptType = plan.aptType;
            }
          });
          console.log('planType:', planType);

          // format phone number
          let formattedPhoneNumber = "+1 (" + formData.phoneNumber.slice(0, 3) + ") " + formData.phoneNumber.slice(3, 6) + "-" + formData.phoneNumber.slice(6);

          // Get the constraints and appointment type
          const constraint1 = process.env.REACT_APP_FORM_CONSTRAINT_1; // "[ 'ALLERGIES', 'dairy', 'mild' ]"
          const constraint2 = process.env.REACT_APP_FORM_CONSTRAINT_2; // "[ 'COMORBIDITY', 'High Blood Pressure', 'mild' ]"
          const constraint3 = process.env.REACT_APP_FORM_CONSTRAINT_3; // "[ 'INTOLERANCE', 'Lactose', 'mild' ]"

          // construct valid JSON array from string
          const parseEnvArray = (envString) => {
            const validJsonString = envString.replace(/'/g, '"');
            try {
              return JSON.parse(validJsonString);
            } catch (error) {
              console.error("Failed to parse environment variable:", error);
              return [];
            }
          };

          // parse the constraints and appointments
          const parsedConstraint1 = parseEnvArray(constraint1);
          const parsedConstraint2 = parseEnvArray(constraint2);
          const parsedConstraint3 = parseEnvArray(constraint3);

          // generate constraints
          const generateConstraints = () => {
            return [
              {
                type: parsedConstraint1[0],
                name: parsedConstraint1[1],
                active: parsedConstraint1[2],
                severity: parsedConstraint1[3],
              },
              {
                type: parsedConstraint2[0],
                name: parsedConstraint2[1],
                active: parsedConstraint2[2],
                severity: parsedConstraint2[3],
              },
              {
                type: parsedConstraint3[0],
                name: parsedConstraint3[1],
                active: parsedConstraint3[2],
                severity: parsedConstraint3[3],
              },
            ];
          };

          // generate appointments
          const generateAppointments = () => {
            const apptDate = new Date();
            apptDate.setHours(apptDate.getHours() + (aptType === 'SURGERY' ? 4 : 24));
            apptDate.setMinutes(apptDate.getMinutes() + 5);
            apptDate.toISOString()
            console.log('appt date:', apptDate);
            return [
              {
                title: formData.plan,
                type: aptType,
                date: (formData.plan.toLowerCase().includes("outreach")) ? null : apptDate,
                providerId: providerId,
                surgeryType: aptType === 'SURGERY' ? 'Outpatient' : '',
              },
            ];
          };

          // Use generated constraints and appointments
          const constraints = generateConstraints();
          const appointments = generateAppointments();

          // set the first name, last name
          formData.firstName = (formData.ctype === 'Custom - All fields' || formData.ctype === 'Minimal - Select fields') ? formData.firstName : firstName;
          formData.lastName = (formData.ctype === 'Custom - All fields' || formData.ctype === 'Minimal - Select fields') ? formData.lastName : lastName;

          // initialize request object
          const requestObject = {
            name: formData.plan + " Test",
            outcomePlanId: {
              id: newFoundPlanId,
              versionNumber: 1
            },
            providerId: providerId,
            patient: {
              firstName: formData.firstName,
              lastName: formData.lastName,
              dob: (formData.ctype === 'Custom - All fields' || formData.ctype === 'Minimal - Select fields') ? formData.dob : dob,
              gender: (formData.ctype === 'Custom - All fields' || formData.ctype === 'Minimal - Select fields') ? formData.gender : randomGender,
              phoneNumber: formattedPhoneNumber,
              postalCode: (formData.ctype === 'Custom - All fields' || formData.ctype === 'Minimal - Select fields') ? formData.postalCode : randomzip,
              mobilePhoneNumber: formattedPhoneNumber,
              mrn: randommrn,
              umrn: randomumrn,
              source: process.env.REACT_APP_FORM_SOURCE,
              pronoun: randompronoun,
              conversationStyle: randomconversationStyle,
              communicationChannel: process.env.REACT_APP_COMMUNICATION_CHANNEL,
              constraintList: constraints,
            },
            state: "ACTIVE",
            communicationChannel: process.env.REACT_APP_COMMUNICATION_CHANNEL,
            searchText: formData.plan + ' ' + formData.firstName + ' ' + formData.lastName,
            appointments: appointments,
            medications: []
          };

          // special case for MIMIT Appointment Reminder
          if (formData.plan === 'MIMIT Appointment Reminder') {
            requestObject.appointments[0].date = new Date(); // Current date
            adate.setDate(adate.getDate() + 10);
            requestObject.appointments[0].date = adate.toISOString();
          }

          // Construct API endpoint
          apiEndpoint = process.env.REACT_APP_CASE_API_ENDPOINT;
          console.log('Request Object:', JSON.stringify(requestObject));

          // Create case
          const response = await fetch(apiEndpoint, {
            method: 'POST',
            headers: {
              'accept': '*/*',
              'Content-Type': 'application/json',
              'Access-Control-Allow-Origin': '*',
              'Authorization': `Bearer ${accessToken}`,
            },
            body: JSON.stringify(requestObject),
          });

          // Check the response
          if (response.ok) {
            setFormData({
              atype: formData.atype,
              plan: '',
              ctype: formData.ctype,
              firstName: '',
              lastName: '',
              dob: '',
              gender: '',
              phoneNumber: '',
              postalCode: '',
            });
            const responseData = await response.json();
            setCaseId(responseData.id);
            console.log('Response Data:', responseData);
          } else {
            // Handle if response is not OK
            const errorData = await response.json();
            console.error('Error:', errorData);
            alert('Error creating case in Mila: ' + errorData);
          }
          break;
        case 'Get case debug info':
          // Fetch API base URL, environment & set request headers
          let apiBaseUrl = process.env.REACT_APP_JARVIS_BASE_URL + formData.caId;
          formData.apienv = process.env.REACT_APP_ENV;
          let reqHeaders = {
            'accept': '*/*',
            'Content-Type': 'application/json',
            'Access-Control-Allow-Origin': '*',
            'Authorization': `Bearer ${accessToken}`,
          }

          // Construct API endpoints
          let apiCaseUrl = apiBaseUrl;
          let apiPatientUrl = apiBaseUrl + '/patient';
          let apiProviderUrl = apiBaseUrl + '/provider';
          let apiOrganizationUrl = apiBaseUrl + '/provider/organization';
          let apiOrganizationGroupUrl = apiBaseUrl + '/provider/organization-group';
          let apiOutcomePlanUrl = apiBaseUrl + '/outcome-plan';
          let apiOutcomePlanTaskGraphTemplatesUrl = apiBaseUrl + '/outcome-plan/task-graph-templates';
          let apiOutcomePlanInstanceUrl = apiBaseUrl + '/outcome-plan-instance';
          let apiOutcomePlanInstanceTaskGraphInstancesUrl = apiBaseUrl + '/outcome-plan-instance/task-graph-instances';
          let apiOutcomePlanInstanceNextExecutionTimeUrl = apiBaseUrl + '/outcome-plan-instance/next-execution-time';
          let apiOutcomePlanInstanceExecutionsUrl = apiBaseUrl + '/outcome-plan-instance/executions';
          let apiOutcomePlanInstanceSchedulerStatusUrl = apiBaseUrl + '/outcome-plan-instance/scheduler/status';
          let apiOutcomePlanInstanceInteractionRequestsUrl = apiBaseUrl + '/outcome-plan-instance/interaction-requests';
          let apiOutcomePlanInstanceInteractionResponsesUrl = apiBaseUrl + '/outcome-plan-instance/interaction-responses';

          // Fetch all case data at once
          const [caseResp, patientResp, providerResp, organizationResp, groupResp, planResp, tgtResp, planInstResp, tgiResp, netResp, execsResp, statResp, intReqResp, intResResp ] = await Promise.all([
            fetch(apiCaseUrl, { method: 'GET', headers: reqHeaders,}),
            fetch(apiPatientUrl, { method: 'GET', headers: reqHeaders, }),
            fetch(apiProviderUrl, { method: 'GET', headers: reqHeaders, }),
            fetch(apiOrganizationUrl, { method: 'GET', headers: reqHeaders, }),
            fetch(apiOrganizationGroupUrl, { method: 'GET', headers: reqHeaders, }),
            fetch(apiOutcomePlanUrl, { method: 'GET', headers: reqHeaders, }),
            fetch(apiOutcomePlanTaskGraphTemplatesUrl, { method: 'GET', headers: reqHeaders, }),
            fetch(apiOutcomePlanInstanceUrl, { method: 'GET', headers: reqHeaders, }),
            fetch(apiOutcomePlanInstanceTaskGraphInstancesUrl, { method: 'GET', headers: reqHeaders, }),
            fetch(apiOutcomePlanInstanceNextExecutionTimeUrl, { method: 'GET', headers: reqHeaders, }),
            fetch(apiOutcomePlanInstanceExecutionsUrl, { method: 'GET', headers: reqHeaders, }),
            fetch(apiOutcomePlanInstanceSchedulerStatusUrl, { method: 'GET', headers: reqHeaders, }),
            fetch(apiOutcomePlanInstanceInteractionRequestsUrl, { method: 'GET', headers: reqHeaders, }),
            fetch(apiOutcomePlanInstanceInteractionResponsesUrl, { method: 'GET', headers: reqHeaders, }),
          ]);
        
          // process responses
          if (caseResp.ok) { setCaseJson(await caseResp.json()); }
          else { console.error('Error:', caseResp); }
          if (patientResp.ok) { setPatientJson(await patientResp.json()); }
          else { console.error('Error:', patientResp);}
          if (providerResp.ok) { setProviderJson(await providerResp.json()); }
          else { console.error('Error:', providerResp); }
          if (organizationResp.ok) { setOrganizationJson(await organizationResp.json()); }
          else { console.error('Error:', organizationResp); }
          if (groupResp.ok) { setOrganizationGroupJson(await groupResp.json()); }
          else { console.error('Error:', groupResp); }
          if (planResp.ok) { setOutcomePlanJson(await planResp.json()); }
          else { console.error('Error:', planResp); }
          if (tgtResp.ok) { setOutcomePlanTaskGraphTemplatesJson(await tgtResp.json()); }
          else { console.error('Error:', tgtResp); }
          if (planInstResp.ok) { setOutcomePlanInstanceJson(await planInstResp.json()); }
          else { console.error('Error:', planInstResp); }
          if (tgiResp.ok) { setOutcomePlanInstanceTaskGraphInstancesJson(await tgiResp.json()); }
          else { console.error('Error:', tgiResp); }
          if (netResp.ok) { setOutcomePlanInstanceNextExecutionTimeJson(await netResp.json()); }
          else { console.error('Error:', netResp); }
          if (execsResp.ok) { setOutcomePlanInstanceExecutionsJson(await execsResp.json()); }
          else { console.error('Error:', execsResp); }
          if (statResp.ok) { setOutcomePlanInstanceSchedulerStatusJson(await statResp.json()); }
          else { console.error('Error:', statResp); }
          if (intReqResp.ok) { setOutcomePlanInstanceInteractionRequestsJson(await intReqResp.json()); }
          else { console.error('Error:', intReqResp); }
          if (intResResp.ok) { setOutcomePlanInstanceInteractionResponsesJson(await intResResp.json()); }
          else { console.error('Error:', intResResp); }

          // set initial tab
          setSelectedTab(0);

          setFormData({
            caId: '',
            atype: formData.atype,
          });
          setCaId(formData.caId);
          break;
        default:
          break;
      }
    } catch (error) {
      // Handle try catch errors
      console.error('Error:', error);
      if (formData.atype === 'Create a test case')
        alert('Error creating case in Mila: ' + error);
      else if (formData.atype === 'Get case debug info')
        alert('Error fetching case information: ' + error);
    } finally {
      setIsSubmitting(false);
    }
  };

  // set the width of the form based on function being called
  if (formData.atype === 'Get case debug info') {
    document.documentElement.style.setProperty('--dynamic-width', '800px');
  } else {
    document.documentElement.style.setProperty('--dynamic-width', '350px'); // default width
  }
  document.documentElement.style.removeProperty('--dynamic-justify-content');

  // Render the form
  return (
    <form onSubmit={handleSubmit}>
      {caseId !== '' && formData.atype === 'Create a test case' && (
      <>
        <h3>
          <label>
            [Case: {caseId}]
          </label>
        </h3>
      </>
      )}

      <div>
        <label>
          Select the Jarvis function using the dropdown:
          <select
            name="atype"
            value={formData.atype}
            onChange={handleChange}
            required
          >
            <option value="">Select the JARVIS function</option>
            {atypes.map((atype, index) => (
              <option key={index} value={atype}>
                {atype}
              </option>
            ))}
          </select>
        </label>
      </div>

      {formData.atype === 'Create a test case' && (
      <div>
        <label>
          Select the outcome plan using the dropdown:
          <select
            name="plan"
            value={formData.plan}
            onChange={handleChange}
            required
          >
            <option value="">Select a Plan</option>
            {plans.map((plan, index) => (
              <option key={index} value={plan}>
                {plan}
              </option>
            ))}
          </select>
        </label>
      </div>
      )}

      {formData.atype === 'Create a test case' && (
      <div>
        <label>
          Select the type of case using the dropdown:
          <select
            name="ctype"
            value={formData.ctype}
            onChange={handleChange}
            required
          >
            <option value="">Select a Case Type</option>
            {ctypes.map((ctype, index) => (
              <option key={index} value={ctype}>
                {ctype}
              </option>
            ))}
          </select>
        </label>
      </div>
      )}

      {formData.atype === 'Create a test case' && (formData.ctype === 'Custom - All fields' || formData.ctype === 'Minimal - Select fields') && (
      <div>
        <label>
          Enter the first name of the patient:
          <input
            type="text"
            name="firstName"
            value={formData.firstName}
            onChange={handleChange}
            required
          />
        </label>
      </div>
      )}

      {formData.atype === 'Create a test case' && (formData.ctype === 'Custom - All fields' || formData.ctype === 'Minimal - Select fields') && (
      <div>
        <label>
          Enter the last name of the patient:
          <input
            type="text"
            name="lastName"
            value={formData.lastName}
            onChange={handleChange}
            required
          />
        </label>
      </div>
      )}

      {formData.atype === 'Create a test case' && (formData.ctype === 'Custom - All fields' || formData.ctype === 'Minimal - Select fields') && (
      <div>
        <label>
          Enter the date of birth (mm/dd/yyyy):
          <input
            type="date"
            name="dob"
            value={formData.dob}
            onChange={handleChange}
            required
          />
        </label>
      </div>
      )}

      {formData.atype === 'Create a test case' && (formData.ctype === 'Custom - All fields' || formData.ctype === 'Minimal - Select fields') && (
      <div>
        <label>
          Select the gender of the patient:
          <select
            name="gender"
            value={formData.gender}
            onChange={handleChange}
            required
          >
            <option value="">Select Gender</option>
            <option value="male">Male</option>
            <option value="female">Female</option>
            <option value="other">Other</option>
          </select>
        </label>
      </div>
      )}

      {formData.atype === 'Create a test case' && (
      <div>
        <label>
          Phone # (10 digits w/o spaces or dashes):
          <input
            type="tel"
            placeholder="1234567890" 
            pattern="[0-9]{10}"
            name="phoneNumber"
            value={formData.phoneNumber}
            onChange={handleChange}
            required
          />
        </label>
      </div>
      )}

      {formData.atype === 'Create a test case' && (formData.ctype === 'Custom - All fields' || formData.ctype === 'Minimal - Select fields') && (
      <div>
        <label>
          Enter the 5 digits zip code of the patient:
          <input
            type="postalcode"
            name="postalCode"
            value={formData.postalCode}
            onChange={handleChange}
            required
          />
        </label>
      </div>
      )}

      {formData.atype === 'Create a test case' && (
      <button type="submit" disabled={isSubmitting}>
        {isSubmitting ? 'Creating the case...' : `${groupIdMap.name} : Create case in ${process.env.REACT_APP_ENV}`}
      </button>
      )}

      {formData.atype === 'Get case debug info' && (
      <div>
        <label>
          Enter Case ID (Mila generated UUID):
          <input
            type="text"
            placeholder="4b592138-dd3d-4339-a0a0-953840bf209a"
            name="caId"
            value={formData.caId}
            onChange={handleChange}
            required
          />
        </label>
      </div>
      )}

      {formData.atype === 'Get case debug info' && (
      <button type="submit" disabled={isSubmitting}>
        {isSubmitting ? 'Fetching case information...' : `${groupIdMap.name} : Fetch case info from ${process.env.REACT_APP_ENV}`}
      </button>
      )}

      {caId !== '' && formData.atype === 'Get case debug info' && (
      <Box sx={{ width: '100%', typography: 'form' }}>
        <Tabs value={selectedTab} onChange={handleChange} centered>
          <Tab key="0" label="Case" />
          <Tab key="1" label="Patient" />
          <Tab key="2" label="Provider" />
          <Tab key="3" label="Plan" />
          <Tab key="4" label="Plan Instance" />
          <Tab key="5" label="Schedule" />
          <Tab key="6" label="Interactions" />
        </Tabs>

        <TabPanel key="0" value={selectedTab} index={0}>
          <h3>Case</h3>
          <JsonViewer data={caseJson} expanded="8" theme="default-dark" show-data-types="false" show-size="false" expand-icon-type="square" show-toolbar="true" />
        </TabPanel>
        <TabPanel key="1" value={selectedTab} index={1}>
          <h3>Patient</h3>
          <JsonViewer data={patientJson} expanded="8" theme="default-dark" show-data-types="false" show-size="false" expand-icon-type="square" show-toolbar="true" />
        </TabPanel>
        <TabPanel key="2" value={selectedTab} index={2}>
          <h3>Provider</h3>
          <JsonViewer data={providerJson} expanded="8" theme="default-dark" show-data-types="false" show-size="false" expand-icon-type="square" show-toolbar="true" />
          <h3>Organization</h3>
          <JsonViewer data={organizationJson} expanded="8" theme="default-dark" show-data-types="false" show-size="false" expand-icon-type="square" show-toolbar="true" />
          <h3>Group</h3>
          <JsonViewer data={organizationGroupJson} expanded="8" theme="default-dark" show-data-types="false" show-size="false" expand-icon-type="square" show-toolbar="true" />
        </TabPanel>
        <TabPanel key="3" value={selectedTab} index={3}>
          <h3>Plan</h3>
          <JsonViewer data={outcomePlanJson} expanded="8" theme="default-dark" show-data-types="false" show-size="false" expand-icon-type="square" show-toolbar="true" />
          <h3>Task Graph Templates</h3>
          <JsonViewer data={outcomePlanTaskGraphTemplatesJson} expanded="8" theme="default-dark" show-data-types="false" show-size="false" expand-icon-type="square" show-toolbar="true" />
        </TabPanel>
        <TabPanel key="4" value={selectedTab} index={4}>
          <h3>Plan Instance</h3>
          <JsonViewer data={outcomePlanInstanceJson} expanded="8" theme="default-dark" show-data-types="false" show-size="false" expand-icon-type="square" show-toolbar="true" />
          <h3>Task Graph Instances</h3>
          <JsonViewer data={outcomePlanInstanceTaskGraphInstancesJson} expanded="8" theme="default-dark" show-data-types="false" show-size="false" expand-icon-type="square" show-toolbar="true" />
          <h3>Executions</h3>
          <JsonViewer data={outcomePlanInstanceExecutionsJson} expanded="8" theme="default-dark" show-data-types="false" show-size="false" expand-icon-type="square" show-toolbar="true" />
        </TabPanel>
        <TabPanel key="5" value={selectedTab} index={5}>
          <h3>Next Execution Time</h3>
          <JsonViewer data={outcomePlanInstanceNextExecutionTimeJson} expanded="8" theme="default-dark" show-data-types="false" show-size="false" expand-icon-type="square" show-toolbar="true" />
          <h3>Scheduler Status</h3>
          <JsonViewer data={outcomePlanInstanceSchedulerStatusJson} expanded="8" theme="default-dark" show-data-types="false" show-size="false" expand-icon-type="square" show-toolbar="true" />
        </TabPanel>
        <TabPanel key="6" value={selectedTab} index={6}>
          <h3>Interaction Requests</h3>
          <JsonViewer data={outcomePlanInstanceInteractionRequestsJson} expanded="8" theme="default-dark" show-data-types="false" show-size="false" expand-icon-type="square" show-toolbar="true" />
          <h3>Interaction Responses</h3>
          <JsonViewer data={outcomePlanInstanceInteractionResponsesJson} expanded="8" theme="default-dark" show-data-types="false" show-size="false" expand-icon-type="square" show-toolbar="true" />
        </TabPanel>
      </Box>
      )}

    </form>
  );
};

export default UserForm;
